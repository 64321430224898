import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a8af05c0 = () => interopDefault(import('../pages/check-email.vue' /* webpackChunkName: "pages/check-email" */))
const _6d2bfdb2 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _f5367dde = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _4a995850 = () => interopDefault(import('../pages/medications/index.vue' /* webpackChunkName: "pages/medications/index" */))
const _7060e8d0 = () => interopDefault(import('../pages/password-updated.vue' /* webpackChunkName: "pages/password-updated" */))
const _3732fcdb = () => interopDefault(import('../pages/paylinks/index.vue' /* webpackChunkName: "pages/paylinks/index" */))
const _d6332c36 = () => interopDefault(import('../pages/prescriptions/index.vue' /* webpackChunkName: "pages/prescriptions/index" */))
const _3098e5ea = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _6a9802ee = () => interopDefault(import('../pages/registered.vue' /* webpackChunkName: "pages/registered" */))
const _a3b47be0 = () => interopDefault(import('../pages/resend-confirmation.vue' /* webpackChunkName: "pages/resend-confirmation" */))
const _6f671340 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _9386992c = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _37e5c6f3 = () => interopDefault(import('../pages/settings/close-account.vue' /* webpackChunkName: "pages/settings/close-account" */))
const _679726ae = () => interopDefault(import('../pages/settings/export.vue' /* webpackChunkName: "pages/settings/export" */))
const _6ee76597 = () => interopDefault(import('../pages/settings/hospitals/index.vue' /* webpackChunkName: "pages/settings/hospitals/index" */))
const _25832579 = () => interopDefault(import('../pages/settings/options.vue' /* webpackChunkName: "pages/settings/options" */))
const _d3fe3ef4 = () => interopDefault(import('../pages/settings/pharmacies.vue' /* webpackChunkName: "pages/settings/pharmacies" */))
const _330286f5 = () => interopDefault(import('../pages/settings/security.vue' /* webpackChunkName: "pages/settings/security" */))
const _7ede5700 = () => interopDefault(import('../pages/settings/user.vue' /* webpackChunkName: "pages/settings/user" */))
const _6f25b6a5 = () => interopDefault(import('../pages/settings/hospitals/new.vue' /* webpackChunkName: "pages/settings/hospitals/new" */))
const _9c5445fc = () => interopDefault(import('../pages/settings/hospitals/_id/index.vue' /* webpackChunkName: "pages/settings/hospitals/_id/index" */))
const _3ad4c3ec = () => interopDefault(import('../pages/settings/hospitals/_id/edit.vue' /* webpackChunkName: "pages/settings/hospitals/_id/edit" */))
const _1b7c1803 = () => interopDefault(import('../pages/embed/rx.vue' /* webpackChunkName: "pages/embed/rx" */))
const _dbd5131a = () => interopDefault(import('../pages/prescriptions/new.vue' /* webpackChunkName: "pages/prescriptions/new" */))
const _4c3a83b4 = () => interopDefault(import('../pages/redirect/new.vue' /* webpackChunkName: "pages/redirect/new" */))
const _b8f74aca = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _357c5b93 = () => interopDefault(import('../pages/paylinks/_slug.vue' /* webpackChunkName: "pages/paylinks/_slug" */))
const _a113a294 = () => interopDefault(import('../pages/prescriptions/_signature.vue' /* webpackChunkName: "pages/prescriptions/_signature" */))
const _b1d3dfd0 = () => interopDefault(import('../pages/new-password/_uid/_token.vue' /* webpackChunkName: "pages/new-password/_uid/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/check-email",
    component: _a8af05c0,
    name: "check-email"
  }, {
    path: "/login",
    component: _6d2bfdb2,
    name: "login"
  }, {
    path: "/logout",
    component: _f5367dde,
    name: "logout"
  }, {
    path: "/medications",
    component: _4a995850,
    name: "medications"
  }, {
    path: "/password-updated",
    component: _7060e8d0,
    name: "password-updated"
  }, {
    path: "/paylinks",
    component: _3732fcdb,
    name: "paylinks"
  }, {
    path: "/prescriptions",
    component: _d6332c36,
    name: "prescriptions"
  }, {
    path: "/register",
    component: _3098e5ea,
    name: "register"
  }, {
    path: "/registered",
    component: _6a9802ee,
    name: "registered"
  }, {
    path: "/resend-confirmation",
    component: _a3b47be0,
    name: "resend-confirmation"
  }, {
    path: "/reset-password",
    component: _6f671340,
    name: "reset-password"
  }, {
    path: "/settings",
    component: _9386992c,
    name: "settings",
    children: [{
      path: "close-account",
      component: _37e5c6f3,
      name: "settings-close-account"
    }, {
      path: "export",
      component: _679726ae,
      name: "settings-export"
    }, {
      path: "hospitals",
      component: _6ee76597,
      name: "settings-hospitals"
    }, {
      path: "options",
      component: _25832579,
      name: "settings-options"
    }, {
      path: "pharmacies",
      component: _d3fe3ef4,
      name: "settings-pharmacies"
    }, {
      path: "security",
      component: _330286f5,
      name: "settings-security"
    }, {
      path: "user",
      component: _7ede5700,
      name: "settings-user"
    }, {
      path: "hospitals/new",
      component: _6f25b6a5,
      name: "settings-hospitals-new"
    }, {
      path: "hospitals/:id",
      component: _9c5445fc,
      name: "settings-hospitals-id"
    }, {
      path: "hospitals/:id/edit",
      component: _3ad4c3ec,
      name: "settings-hospitals-id-edit"
    }]
  }, {
    path: "/embed/rx",
    component: _1b7c1803,
    name: "embed-rx"
  }, {
    path: "/prescriptions/new",
    component: _dbd5131a,
    name: "prescriptions-new"
  }, {
    path: "/redirect/new",
    component: _4c3a83b4,
    name: "redirect-new"
  }, {
    path: "/",
    component: _b8f74aca,
    name: "index"
  }, {
    path: "/paylinks/:slug",
    component: _357c5b93,
    name: "paylinks-slug"
  }, {
    path: "/prescriptions/:signature",
    component: _a113a294,
    name: "prescriptions-signature"
  }, {
    path: "/new-password/:uid?/:token?",
    component: _b1d3dfd0,
    name: "new-password-uid-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
