import { render, staticRenderFns } from "./MinimalLayout.vue?vue&type=template&id=0e399608&"
import script from "./MinimalLayout.vue?vue&type=script&lang=ts&"
export * from "./MinimalLayout.vue?vue&type=script&lang=ts&"
import style0 from "./MinimalLayout.vue?vue&type=style&index=0&id=0e399608&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./MinimalLayout.vue?vue&type=custom&index=0&blockType=i18n&locale=es&lang=yaml"
if (typeof block0 === 'function') block0(component)
import block1 from "./MinimalLayout.vue?vue&type=custom&index=1&blockType=i18n&locale=en&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SkipLink: require('/tmp/build_893d9df2/components/SkipLink.vue').default,MobileMenu: require('/tmp/build_893d9df2/components/MobileMenu.vue').default,DesktopMenu: require('/tmp/build_893d9df2/components/DesktopMenu.vue').default,ContactSlideOver: require('/tmp/build_893d9df2/components/ContactSlideOver.vue').default,MaterialIcon: require('/tmp/build_893d9df2/components/MaterialIcon.vue').default,Button: require('/tmp/build_893d9df2/components/Button.vue').default})
